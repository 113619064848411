import { Box, Button, Grid, Stack, Modal, InputLabel,MenuItem,FormControl,Select  } from '@mui/material';
import './App.css';
import './fonts/Lobster-Regular.ttf'
import './fonts/Kalam-Regular.ttf'
import './fonts/ArchivoBlack-Regular.ttf'
import './fonts/Orbitron-Bold.ttf'
import TelegramProfileImage from './TelegramProfileImage';
import loadingImage from './images/loading.gif'
import axios from 'axios';
import watermelonIcon from './images/watermelon.png'
import PersonPinIcon from '@mui/icons-material/PersonPin';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ShowerIcon from '@mui/icons-material/WaterDrop';
import BackpackIcon from '@mui/icons-material/EnergySavingsLeaf';
import BatteryStdIcon from '@mui/icons-material/Agriculture';
import LockIcon from '@mui/icons-material/Lock';
import Odometer from './odometer'
import CountdownTimer from './CountdownTimer';
import io from 'socket.io-client';
import React, { useEffect, useState, useRef } from 'react';
import { useInitData,useExpand, BackButton } from '@vkruglikov/react-telegram-web-app';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { People } from '@mui/icons-material';
import IosShareIcon from '@mui/icons-material/IosShare';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import moment from 'moment-timezone'
import LinearProgress from '@mui/material/LinearProgress';

function App() {
  const [open, setOpen] = React.useState(false);
  const handleClickSnackbar = () => {
    setOpen(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const socket = useRef(null);
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const [currentInput, setCurrentInput] = useState(input1Ref);
  const handleFocus = (ref) => {
    setCurrentInput(ref);
    ref.current.focus();
  };

  const [openSwapPanel, setOpenSwapPanel] = useState(false);
  const handleOpenSwapPanel = () => setOpenSwapPanel(true);
  const handleCloseSwapPanel = () => setOpenSwapPanel(false);

  const [openModalWallet, setOpenModalWallet] = useState(false);
  const handleOpenModalWallet = () => setOpenModalWallet(true);
  const handleCloseModalWallet = () => setOpenModalWallet(false);

  const [openLoading, setOpenLoading] = useState(true);
  const handleOpenLoading = () => setOpenLoading(true);
  const handleCloseLoading = () => setOpenLoading(false);

  const [openModalSellMelon, setOpenModalSellMelon] = useState(false);
  const handleOpenModalSellMelon = () => setOpenModalSellMelon(true);
  const handleCloseModalSellMelon = () => setOpenModalSellMelon(false);

  const [openModalProfile, setOpenModalProfile] = useState(false);
  const handleOpenModalProfile = () => setOpenModalProfile(true);
  const handleCloseModalProfile = () => setOpenModalProfile(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [timeNow, setTimeNow] = useState('-')

  const [openModalRegister, setOpenModalRegister] = useState(true);
  const handleOpenModalRegister = () => setOpenModalRegister(true);
  const handleCloseModalRegister = () => setOpenModalRegister(false);

  const [isGameStart, setIsGameStart] = useState(true);
  const [day, setDay] = useState('-')
  const [session, setSession] = useState('-')
  const [timeDay, setTimeDay] = useState('-')
  const [climedCoin, setClimedCoin] = useState('-')

  const [climedCoinWitoutPoint, setClimedCoinWitoutPoint] = useState(0)
  const [climedCoinWithPoint, setClimedCoinWithPoint] = useState(0)
  const [tokenToClime, setTokenToClime] = useState(0)
  const [allTokenClimed, setAllTokenClimed] = useState(0)
  const [balance, setBalance] = useState(0)
  const [allBuyCost, setAllBuyCost] = useState(0)
  const [timeToClime, setTimeToClime] = useState('')
  const [plan, setPlan] = useState('')
  const [isTokenClimeFull, setIsTokenClimeFull] = useState(false)
  const [isClimeClose, setIsClimeClose] = useState(false)
  const [isWater, setIsWater] = useState(false)
  const [isFertilizer, setIsFertilizer] = useState(false)//K
  const [isPoisons, setIsPoisons] = useState(false)//S
  const [isBuyWater, setIsBuyWater] = useState(false)
  const [isBuyFertilizer, setIsBuyFertilizer] = useState(false)//K
  const [isBuyPoisons, setIsBuyPoisons] = useState(false)//S
  const [isBuyGround, setIsBuyGround] = useState(false)
  const [isBuyGroundCompleted, setIsBuyGroundCompleted] = useState(false)
  const [allFriends, setAllfriends] = useState(0)
  const [isLockedGround1, setIsLockedGround1] = useState(true)
  const [isLockedGround2, setIsLockedGround2] = useState(true)
  const [isLockedGround3, setIsLockedGround3] = useState(true)
  const [isLockedGround4, setIsLockedGround4] = useState(true)
  const [groundNumber, setGroundNumber] = useState(0)
  const [dataSwap, setDataSwap] = useState([]);
  const [dataFriends, setDataFriends] = useState([]);
  const [initDataUnsafe] = useInitData();
  
  const [user, setUser] = useState(null);
  const [telegramId, setTelegramId] = useState(null);
  const [profileUrl, setProfileUrl] = useState('-')
  const [profileName, setProfileName] = useState('-')
  var tokenClimeInSeconds = process.env.REACT_APP_TCIS
  
  const [isExpanded, expand] = useExpand();
  const handleClick = () => !isExpanded && expand();
  const telegramToken = process.env.REACT_APP_TELEGRAMTOKEN
  const botId = process.env.REACT_APP_BOTID
  const [handleOnregisterBtn, setHandleOnregisterBtn] = useState(true);
  const [handleOnStartFarmingBtn, setHandleOnStartFarmingBtn] = useState(true);
  
  const bg = ''
  const [swapCodeConnect, setSwapCodeConnect] = React.useState('');
  const handleOnSwapCodeConnect = (event) => {
    setSwapCodeConnect(event.target.value);
  };
  const [inputValueSwapCode, setInputValueSwapCode] = useState('');
  const handleChangeSwapCode = (event) => {
    setInputValueSwapCode(event.target.value);
  };
  const [connectSwap,setConnectSwap] = useState(false)
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [showSellBtn, setShowSellBtn] = useState(false);
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      setUser(initDataUnsafe.user?.username)
      setTelegramId(initDataUnsafe.user?.id)
      setProfileName(initDataUnsafe.user?.first_name)
      handleClick()
    }
  }, []);

  

  var isgetOptions = false;
  var tokenClimeInSeconds = process.env.REACT_APP_TCIS
  useEffect(() => {
    if (telegramId) {
      const urlParams = new URLSearchParams(window.location.search);
      const referrerUrl = urlParams.get(process.env.REACT_APP_REFURL);
      const sP = urlParams.get(process.env.REACT_APP_SPARAMETR);
      const refUsername = urlParams.get(process.env.REACT_APP_USERNAME);
      const refFirstName = urlParams.get(process.env.REACT_APP_FIRNAME);
  
      const fetchData = async () => {
        try {
         
          // Get user token
          const data = await axios.post(`${process.env.REACT_APP_S}`, { telegramId });
          // console.log(data.status);
          getFriendsList()
          getBalance()
          // Handle referrals if any
          if (referrerUrl !== '' && sP !== '' && sP !== referrerUrl ) {
          try {
            await axios.post(`${process.env.REACT_APP_REF}`, {
              referrer: referrerUrl,
              telegramId: sP,
              username: refUsername,
              firstName: refFirstName
            });
            console.log("there");
          } catch (error) {
            console.log(error);
          }
           
           
          }
          if (!isgetOptions) {
            if (data.isWater) {
              tokenClimeInSeconds = tokenClimeInSeconds + process.env.REACT_APP_TCISP
            }
            if (data.isFertilizer) {
              tokenClimeInSeconds = tokenClimeInSeconds +process.env.REACT_APP_TCISP
            }
            if (data.isPoisons) {
              tokenClimeInSeconds = tokenClimeInSeconds + process.env.REACT_APP_TCISP
            }
          }
          console.log(data.data.allTokenClimed);
          if(data.data.allTokenClimed === 0 || data.data.allTokenClimed === '0'){
            setShowSellBtn(false)
          }else{
            setShowSellBtn(true)
          }
          // Update lock states
          updateLockStates(data.data);
          isgetOptions = true
          // Fetch data periodically
          const interval = setInterval(async () => {
            updateStateWithData(data.data);
          }, 1000);
  
          return () => {
            clearInterval(interval);
          };
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }
  }, [telegramId]);

  const updateStateWithData = (data) => {
   
    setIsWater(data.isWater);
    setIsFertilizer(data.isFertilizer);
    setIsPoisons(data.isPoisons);
    setTokenToClime(data.maxTokeForclime.toFixed(2));
    setSession(data.session);
    setPlan(data.plan);
    handleCloseLoading();
    if (data.plan != 0) {
      setIsBuyGroundCompleted(true);
    }
    
    
    
    if (data.registered) {
      setOpenModalRegister(false);
      
    } else {
      setOpenModalRegister(true);
     
    }
    
    if(data.isSwapConnect){
      setConnectSwap(true)
    }else{
      setConnectSwap(false)
    }
    updateClimeData(data);
  };
  const updateLockStates = (data) => {
    setAllfriends(data.invitedFriends)
    if(data.invitedFriends>=5 && data.plan == 0){
      setIsLockedGround1(false);
    }
    if(data.invitedFriends>=10 && data.plan == 0 && data.isCompleteGround1 ){
      setIsLockedGround2(false);
    }
    if(data.invitedFriends>=15 && data.plan == 0 && data.isCompleteGround2 ){
      setIsLockedGround3(false);
    }
    if(data.invitedFriends>=20 && data.plan == 0 && data.isCompleteGround3 ){
      setIsLockedGround4(false);
    }
  };
  const updateClimeData = (data) => {
    const date = moment().tz('UTC');
    const dayOfWeek = date.day();
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayName = daysOfWeek[dayOfWeek];
    setDay(data.day + " Day");
    setTimeDay(dayName);
    if (dayName === "Saturday" || dayName === "Sunday") {
      setIsClimeClose(true);
      handleOpenModal();
    } else {
      handleCloseModal();
      setIsClimeClose(false);
    }
  
    const seconds = Math.floor((date.valueOf() - data.dateRegistred) / 1000);
    var tokenClimed = (seconds * tokenClimeInSeconds).toFixed(2);
    
    var tc = tokenClimed.toString().split('.')[0];
    var te = tokenClimed.toString().split('.')[1];
    
    const timeDiff = Math.abs(data.nextTimeToClime - date.valueOf());
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const second = Math.floor((timeDiff / 1000) % 60);
    var formattedTime = '';
   
    if (date.valueOf() >= data.nextTimeToClime) {
      formattedTime = "claim Your Token";
      setIsTokenClimeFull(true);
      setClimedCoin(tokenToClime);
      const regex = /\d+\.\d+/;
      if (regex.test(data.maxTokeForclime.toFixed(2))) {
        setClimedCoinWitoutPoint(data.maxTokeForclime.toFixed(2).split('.')[0]);
        setClimedCoinWithPoint(data.maxTokeForclime.toFixed(2).split('.')[1]);
      } else {
        setClimedCoinWitoutPoint(data.maxTokeForclime.toFixed(2));
      }
    } else {
      formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
      setClimedCoin(tokenClimed);
      setClimedCoinWitoutPoint(tc);
      setClimedCoinWithPoint(te);
    }
    setTimeToClime(formattedTime);
    setAllTokenClimed(data.allTokenClimed);
    const formattedDate = date.format('YYYY-MM-DD HH:mm:ss').toLocaleString();
    setTimeNow(formattedDate);
  };

  const registerUser = async () => {
    setHandleOnregisterBtn(false);
    
    await axios.post(`${process.env.REACT_APP_RU}`, {
      telegramUsername: user,
      telegramId: telegramId,
      profilePhoto: profileUrl,
      firstName: profileName
    });
    handleRefresh();
  };
  const connectedSwap = async () => {
    
    await axios.post(`${process.env.REACT_APP_CS}`, {
      telegramId: telegramId,
    });
    handleRefresh();
  };
  const goToForme = async () => {
    setLoading(true)
    setDisableButton(true)
    setHandleOnStartFarmingBtn(false);
    await axios.post(`${process.env.REACT_APP_BG}`, {
      telegramId: telegramId,
      plan: groundNumber,
      isWater: isBuyWater,
      isFertilizer: isBuyFertilizer,
      isPoisons: isBuyPoisons
    });
    handleRefresh();
  };
  const handleRefresh = () => {
    
    window.location.reload();
    
  };
  const onClimeTokenClick = async () => {
    await axios.post(`${process.env.REACT_APP_CT}`, { telegramId: telegramId });
    handleRefresh();
  };
  const buyWater = () => {
    setIsBuyWater(true)
  }
  const buyFertilizer = () => {
    setIsBuyFertilizer(true)
  }
  const buyPoisons = () => {
    setIsBuyPoisons(true);
  }
  const getBalance= async()=>{
    const res = await axios.get(`${process.env.REACT_APP_GBG}?user_id=${telegramId}`);
    setBalance(res.data.wmlon)
    return res
  }
  const buyGround = async(number) => {
    setLoading(true)
    setDisableButton(true)
    var res= await getBalance()
    if(res.data === "User not found"){
      console.log("usre not found");
    }else{
      if(number ===1){
        
        if(res.data.wmlon>=Number(process.env.REACT_APP_G_ONE)){
           
          setIsBuyGround(true)
          setGroundNumber(number)
        }else{
          // alert('not enough melon')
        }
      }
    
    }
    
    setDisableButton(false)
    setLoading(false)
  }
  const closeProfile = () => {
    handleCloseModalProfile()
  }
  // const onMarketSellMelon = async () => {
    

    // handleOpenModalSellMelon();
  
  // };
  const onSellMelon = async () => {
    setLoading(true)
    setDisableButton(true)
    
    await axios.post(`${process.env.REACT_APP_SM}`,{telegramId:telegramId});
    handleRefresh()
  };
  const getFriendsList = async () => {  
    try {
      const data = await axios.post(`${process.env.REACT_APP_GDF}`, { telId: telegramId });
      setDataFriends(data.data)
    } catch (error) {
      console.log(error);
    }
    
   
  };
   const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  
  const onReferralBtn = ()=>{
    navigator.clipboard.writeText(`https://t.me/superarbuz_bot/?start=${telegramId}`)
    handleClickSnackbar()
  }
  // Function to show popup and copy text
  const onPlusSetAllBuyCost=(cost)=>{
    setAllBuyCost(allBuyCost+cost)
  }
  const onMinesSetAllBuyCost=(cost)=>{
    setAllBuyCost(allBuyCost-cost)
  }
  const onSwapConnectClick = ()=>{
    setInputValueSwapCode('')
    if(inputValueSwapCode == process.env.REACT_APP_SGC && swapCodeConnect == process.env.REACT_APP_SCS){
      console.log("connect");
      handleCloseSwapPanel()
      connectedSwap()
    }else{
      console.log("Unconnect");
    }
  }
  return (
    <div>
      {openLoading ?
        <Modal
          open={handleOpenLoading}
          hideBackdrop={true}
          onClose={handleCloseLoading}>
          <Box sx={{ height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={loadingImage} alt='loadingImage' style={{ width: '100px', height: '100px' }} />
          </Box>
        </Modal> :openModalProfile ?
              <Modal open={openModalProfile}
                hideBackdrop={true}
                onClose={handleCloseModalProfile}>
                <Stack justifyContent={'space-between'} direction={'column'} sx={{ backgroundColor: '#222831', height: '100%', padding: '20px', textAlign: 'center', color: '#EEEEEE', fontFamily: 'OrbitronBold' }}>
                  <Stack direction={'column'} justifyContent={'space-evenly'}>
                    <p>{user}</p>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                    <TelegramProfileImage botId={botId} userId={telegramId} telegramToken={telegramToken} />
                      <p>{profileName}</p>
                    </Stack>
                    <Box justifyContent={'space-evenly'} sx={{textAlign:'left',marginTop:'20px' }}><span>Referral link:</span>  <IosShareIcon onClick={onReferralBtn} sx={{color:'#20b92d',cursor:'pointer'}}/></Box>
                  </Stack>
                 <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message="The referral link was copied"
                    action={action}
                  />
                  <List sx={{ width: '100%',height:'100%', bgcolor: '#222831',overflowX:'hidden',overflowY:'scroll' }}>
                    {dataFriends.map((item) => (
                      <ListItem
                      sx={{paddingLeft:'16px',backgroundColor:'#0403034a',margin:'2px',borderRadius:'5px',padding:'1px', color:'#fff',fontFamily:'ArchivoBlackRegular'}}
                        key={item._id}
                        disableGutters
                      >
                        <Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'} >
                          <TelegramProfileImage botId={botId} userId={item.referralId} telegramToken={telegramToken} />
                          <Stack sx={{marginLeft:'16px'}}>
                            <span>{`name: ${item.firstName} `}</span>
                            <span style={{fontSize:'10px',color:'gray'}}>{`Username: ${item.username}`}</span>
                          </Stack>
                        </Stack>
                      </ListItem>
                    ))}
                  </List>
                  <Button onClick={closeProfile} sx={{ backgroundColor: '#950202', fontFamily: 'ArchivoBlackRegular', color: '#ffffff', marginBottom: '40px' }} variant="contained">close</Button>
                </Stack>
              </Modal> :
        openModal && !openModalRegister ?
          openModalSellMelon?
          // <Modal
          //   open={handleOpenModalSellMelon}
          //   hideBackdrop={true}
          //   sx={{height:'100vh'}}
          //   onClose={handleCloseModalSellMelon}>
          //   <Stack direction={'column'} sx={{paddingLeft:'16px',paddingRight:'16px',textAlign:'center',height:'100%'}}>
          //     <Box sx={{position:'absolute',top:'16px',color:'red',cursor:'pointer'}}>
          //       <KeyboardReturnIcon onClick={handleCloseModalSellMelon} sx={{fontSize:'35px'}}/>
          //     </Box>
          //     <h4 style={{color:'#08ecf6'}}>Amount: {allTokenClimed} melon <br />Ground number: {plan}</h4>
          
          //       <Box onClick={onSellMelon} sx={{backgroundColor:'#29f608',padding:'5px',color:'green',borderRadius:'5px',fontFamily:'KalamRegular',cursor:'pointer'}}>
          //         Sell Your Melon
          //       </Box>
              
          //     <List sx={{ width: '100%',height:'100%',marginBottom:'16px',marginTop:'16px', bgcolor: '#222831',overflowX:'hidden',overflowY:'scroll' }}>
          //       {dataSwap.map((item) => (
          //         <ListItem
          //           key={item._id}
          //           disableGutters
          //         >
          //           <Stack direction={'column'}  sx={{marginLeft:'16px',marginRight:'16px', marginTop:'10px', color:'#fff',fontFamily:'ArchivoBlackRegular',width:'100%'}} >
          //             <span>{`Amount: ${item.amount} M`}</span>
          //             <span style={{fontSize:'12px',color:'gray'}}>{`Username: ${item.username}`}</span>
          //             <span style={{fontSize:'12px',color:'gray',width:'100%',textAlign:'right'}} >{`Time:  ${item.date}`}</span>
          //             <div style={{height:'1px',background:'red'}}></div>
          //           </Stack>
                    
          //         </ListItem>
          //       ))}
          //     </List>
              
              
          //   </Stack>
          // </Modal>
          <Box/>
          :<Modal
            open={openModal}
            hideBackdrop={true}
            onClose={handleCloseModal}>
            <Stack direction={'column'} justifyContent={'space-between'} sx={{ paddingBottom: '20px', height: "100%", flex: 1 }}>
            {loading?<Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>:<Box/>}
              {/* {isBuyGroundCompleted?<Box/>:<Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'} sx={{ flexGrow: 2 }}>
                <Stack alignItems={'center'} sx={{ backgroundColor: '#152515', borderRadius: '5px', paddingTop: '5px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)' }}>
                  <ShowerIcon sx={{ width: "30px", height: "30px", color: "#08ecf6" }} />
                  <div style={{ color: '#08ecf6', fontSize: '14px', fontFamily: 'KalamRegular', paddingLeft: '5px', paddingRight: '5px' }}>
                     4800
                     melon
                  </div>
                  {isBuyWater ? <Box onClick={()=>{setIsBuyWater(false);onMinesSetAllBuyCost(4800)}} sx={{ padding: "3px", color: "#ffffff", width: '100%', height: '24px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#f60808', cursor: "pointer", textAlign: "center", fontFamily: 'LobsterRegular' }}>
                    return
                  </Box> : <Box onClick={()=>{buyWater();onPlusSetAllBuyCost(4800)}} sx={{ padding: "3px", color: "#45EBA5", width: '100%', height: '24px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#29f608', cursor: "pointer", textAlign: "center", color: '#008000', fontFamily: 'LobsterRegular' }}>
                    BUY
                  </Box>}
                </Stack>
                <Stack alignItems={'center'} sx={{ backgroundColor: '#152515', borderRadius: '5px', paddingTop: '5px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)' }}>
                  <BackpackIcon sx={{ width: "30px", height: "30px", color: "#08ecf6" }} />
                  <div style={{ color: '#08ecf6', fontSize: '14px', fontFamily: 'KalamRegular', paddingLeft: '5px', paddingRight: '5px' }}>
                  4800 melon
                  </div>
                  {isBuyFertilizer ? <Box onClick={()=>{setIsBuyFertilizer(false);onMinesSetAllBuyCost(4800)}} sx={{ padding: "3px", color: "#ffffff", width: '100%', height: '24px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#f60808', cursor: "pointer", textAlign: "center", fontFamily: 'LobsterRegular' }}>
                    return
                  </Box> : <Box onClick={()=>{buyFertilizer();onPlusSetAllBuyCost(4800)}} sx={{ padding: "3px", color: "#45EBA5", width: '100%', height: '24px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#29f608', cursor: "pointer", textAlign: "center", color: '#008000', fontFamily: 'LobsterRegular' }}>
                    BUY
                  </Box>}
                </Stack>
                <Stack alignItems={'center'} sx={{ backgroundColor: '#152515', borderRadius: '5px', paddingTop: '5px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)' }}>
                  <BatteryStdIcon sx={{ width: "30px", height: "30px", color: "#08ecf6" }} />
                  <div style={{ color: '#08ecf6', fontSize: '14px', fontFamily: 'KalamRegular', paddingLeft: '5px', paddingRight: '5px' }}>
                  4800 melon
                  </div>
                  {isBuyPoisons ? <Box onClick={()=>{setIsBuyPoisons(false);onMinesSetAllBuyCost(4800)}} sx={{ padding: "3px", color: "#ffffff", width: '100%', height: '24px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#f60808', cursor: "pointer", textAlign: "center", fontFamily: 'LobsterRegular' }}>
                    return
                  </Box> : <Box onClick={()=>{buyPoisons();onPlusSetAllBuyCost(4800)}} sx={{ padding: "3px", color: "#45EBA5", width: '100%', height: '24px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#29f608', cursor: "pointer", textAlign: "center", color: '#008000', fontFamily: 'LobsterRegular' }}>
                    BUY
                  </Box>}
                </Stack>
                
              </Stack>} */}
              <Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'} sx={{ backgroundColor: '#222831', borderRadius: '5px', paddingTop: '5px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)' }}>
                  <div style={{ color: '#08ecf6', fontSize: '14px', fontFamily: 'KalamRegular', paddingLeft: '5px', paddingRight: '5px' }}>
                    claimed: {allTokenClimed} Melon
                  </div>
                  <div style={{ color: '#08ecf6', fontSize: '14px', fontFamily: 'KalamRegular', paddingLeft: '5px', paddingRight: '5px' }}>
                    plan: {plan}
                  </div>
                  <div style={{ color: '#08ecf6', fontSize: '14px', fontFamily: 'KalamRegular', paddingLeft: '5px', paddingRight: '5px' }}>
                    All Buy: {allBuyCost}
                  </div>
                  <div style={{ color: '#08ecf6', fontSize: '14px', fontFamily: 'KalamRegular', paddingLeft: '5px', paddingRight: '5px' }}>
                    balance: {balance}
                  </div>
                  
              </Stack>
              <Stack direction={'row'} justifyContent={'space-evenly'} >
                {showSellBtn?<Button disabled={disableButton} onClick={onSellMelon}  sx={{width:'100%', marginTop: '8px', color: '#ffffff', fontSize: '22px', fontFamily: 'LobsterRegular', height: '30px',  backgroundColor: '#f60808', borderRadius: '5px', padding: '4px',margin:'10px', textAlign: 'center', cursor: 'pointer' }}>Sell Melon</Button>:<Box/>}
                {isBuyGround && handleOnStartFarmingBtn?<Button disabled={disableButton} onClick={goToForme}  sx={{width:'100%', marginTop: '8px', color: 'green', fontSize: '22px', fontFamily: 'LobsterRegular', height: '30px',  backgroundColor: '#29f608', borderRadius: '5px', padding: '4px',margin:'10px', textAlign: 'center', cursor: 'pointer' }}>
                    Start Farming
                  </Button>:<Box/>}
              </Stack>
              <Stack direction={'row'} justifyContent={'space-evenly'} sx={{ flexGrow: 10 ,marginBottom:'40px',overflowY:'scroll'}}>
                <Stack direction={'column'} justifyContent={'space-evenly'}  >
                    <Stack direction={'row'} justifyContent='space-between' sx={{ backgroundColor: '#222831', borderRadius: '20px',  color: '#2b5f49', boxShadow: '#191720 0 0 12px 2px',overflow:'hidden' }}>
                      <Stack  direction='column' justifyContent={'center'} alignItems={'center'} sx={{position:'relative',width:'100%',overflow:'hidden'}}>
                        <div  style={{ color: '#29f608', fontSize: '16px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                          Ground 1
                        </div>
                        <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                          15 $/ 30 $
                        </div>
                        <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                          21600 Melon / 43200 Melon in Mounth
                        </div>


                        <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                          0.0125 Melon / 0.025 Melon in Second
                        </div>
                        <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                          maximon with option = 64800 Melon
                        </div>
                        <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                          fee = 5400 Melon
                        </div>
                        {isBuyGround ?<Button  onClick={()=>{setIsBuyGround(false);setGroundNumber(0);onMinesSetAllBuyCost(27000)}} sx={{ padding: "3px", color: "#ffffff", width: '100%', height: '24px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#f60808', cursor: "pointer", textAlign: "center", fontFamily: 'LobsterRegular' }}>
                          return
                        </Button> : <Button disabled={disableButton} onClick={() => {buyGround(1) ; onPlusSetAllBuyCost(27000)}} sx={{ marginTop: '8px', color: 'green', fontSize: '22px', fontFamily: 'LobsterRegular', height: '30px', width: '100%', backgroundColor: '#29f608', borderRadius: '5px', padding: '2px', textAlign: 'center', cursor: 'pointer' }}>Buy</Button>}          
                        <h3 style={{ position: 'absolute', color: '#00fa9a1c', fontSize: '24px', fontFamily: 'LobsterRegular', marginTop: '0', textAlign: 'center' }}>Ground Number <br /> 1</h3>
                        {isLockedGround1?<div style={{position:'absolute',top:0,zIndex:'1',left:0 ,width:'100%',height:'100%',backgroundColor:'red',background: '#00000087',display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                          <LockIcon sx={{ width: "40px", height: "40px", color: "#08ecf6" ,borderRadius:'15px',zIndex:'2'}}/>
                          <h3 style={{zIndex:'2',color:'#08ecf6',fontFamily:'KalamRegular',fontSize:'14px',flexGrow:'0.1'}}> Invite +5 friends</h3>
                        </div>:<div></div>}
                      </Stack>
                    </Stack>
                  <Stack direction={'row'} justifyContent='space-between' sx={{ backgroundColor: '#222831', borderRadius: '20px',  color: '#2b5f49', boxShadow: '#191720 0 0 12px 2px',overflow:'hidden' }}>
                    <Stack  direction='column' justifyContent={'center'} alignItems={'center'} sx={{position:'relative',width:'100%',overflow:'hidden'}}>
                      <div  style={{ color: '#29f608', fontSize: '16px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        Ground 3
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        -- $/ -- $
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        --- Melon / --- Melon in Mounth
                      </div>


                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        --- Melon / --- Melon in Second
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        maximon with option = --- Melon
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                          fee = --- Melon
                      </div>
                      {isBuyGround ? <Box onClick={()=>{setIsBuyGround(false);setGroundNumber(0);onMinesSetAllBuyCost(100)}} sx={{ padding: "3px", color: "#ffffff", width: '100%', height: '24px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#f60808', cursor: "pointer", textAlign: "center", fontFamily: 'LobsterRegular' }}>
                          return
                        </Box> : <Box onClick={() => {buyGround(3) ; onPlusSetAllBuyCost(100)}} sx={{ marginTop: '8px', color: 'green', fontSize: '22px', fontFamily: 'LobsterRegular', height: '30px', width: '100%', backgroundColor: '#29f608', borderRadius: '5px', padding: '2px', textAlign: 'center', cursor: 'pointer' }}>Buy</Box>}          
                      <h3 style={{ position: 'absolute', color: '#00fa9a1c', fontSize: '24px', fontFamily: 'LobsterRegular', marginTop: '0', textAlign: 'center' }}>Ground Number <br /> 3</h3>
                      {isLockedGround3?<div style={{position:'absolute',top:0,zIndex:'1',left:0 ,width:'100%',height:'100%',backgroundColor:'red',background: '#00000087',display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                        <LockIcon sx={{ width: "40px", height: "40px", color: "#08ecf6" ,borderRadius:'15px',zIndex:'2'}}/>
                        <h3 style={{zIndex:'2',color:'#08ecf6',fontFamily:'KalamRegular',fontSize:'14px',flexGrow:'0.1'}}>Complete Ground 2 <br /> Invite +15 friends</h3>
                      </div>:<div></div>}
                      

                    </Stack>
                    
                  </Stack>
                </Stack>
                <Stack direction={'column'} justifyContent={'space-evenly'}>
                  <Stack direction={'row'} justifyContent='space-between' sx={{ backgroundColor: '#222831', borderRadius: '20px',  color: '#2b5f49', boxShadow: '#191720 0 0 12px 2px',overflow:'hidden' }}>
                    <Stack  direction='column' justifyContent={'center'} alignItems={'center'} sx={{position:'relative',width:'100%',overflow:'hidden'}}>
                      <div  style={{ color: '#29f608', fontSize: '16px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        Ground 2
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        -- $/ -- $
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        --- Melon / --- Melon in Mounth
                      </div>


                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                       --- Melon / --- Melon in Second
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                       maximon with option = --- Melon
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                          fee = --- Melon
                        </div>
                      {isBuyGround ? <Box onClick={()=>{setIsBuyGround(false);setGroundNumber(0);onMinesSetAllBuyCost(100)}} sx={{ padding: "3px", color: "#ffffff", width: '100%', height: '24px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#f60808', cursor: "pointer", textAlign: "center", fontFamily: 'LobsterRegular' }}>
                          return
                        </Box> : <Box onClick={() => {buyGround(2) ; onPlusSetAllBuyCost(100)}} sx={{ marginTop: '8px', color: 'green', fontSize: '22px', fontFamily: 'LobsterRegular', height: '30px', width: '100%', backgroundColor: '#29f608', borderRadius: '5px', padding: '2px', textAlign: 'center', cursor: 'pointer' }}>Buy</Box>}          
                      <h3 style={{ position: 'absolute', color: '#00fa9a1c', fontSize: '24px', fontFamily: 'LobsterRegular', marginTop: '0', textAlign: 'center' }}>Ground Number <br /> 2</h3>
                      {isLockedGround2?<div style={{position:'absolute',top:0,zIndex:'1',left:0 ,width:'100%',height:'100%',backgroundColor:'red',background: '#00000087',display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                        <LockIcon sx={{ width: "40px", height: "40px", color: "#08ecf6" ,borderRadius:'15px',zIndex:'2'}}/>
                        <h3 style={{zIndex:'2',color:'#08ecf6',fontFamily:'KalamRegular',fontSize:'14px',flexGrow:'0.1'}}>Complete Ground 1 <br /> Invite +10 friends</h3>
                      </div>:<div></div>}
                      

                    </Stack>
                    
                  </Stack>
                  <Stack direction={'row'} justifyContent='space-between' sx={{ backgroundColor: '#222831', borderRadius: '20px',  color: '#2b5f49', boxShadow: '#191720 0 0 12px 2px',overflow:'hidden' }}>
                    <Stack  direction='column' justifyContent={'center'} alignItems={'center'} sx={{position:'relative',width:'100%',overflow:'hidden'}}>
                      <div  style={{ color: '#29f608', fontSize: '16px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        Ground 4
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        -- $/ -- $
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        --- Melon / --- Melon in Mounth
                      </div>


                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        --- Melon / --- Melon in Second
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                        maximon with option = --- Melon
                      </div>
                      <div style={{padding:'4px', color: '#08ecf6', fontSize: '10px', fontFamily: 'KalamRegular', textAlign: "center" }}>
                          fee = --- Melon
                        </div>
                      {isBuyGround ? <Box onClick={()=>{setIsBuyGround(false);setGroundNumber(0);onMinesSetAllBuyCost(100)}} sx={{ padding: "3px", color: "#ffffff", width: '100%', height: '24px', boxShadow: '-1px -4px 5px 0px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#f60808', cursor: "pointer", textAlign: "center", fontFamily: 'LobsterRegular' }}>
                          return
                        </Box> : <Box onClick={() => {buyGround(4) ; onPlusSetAllBuyCost(100)}} sx={{ marginTop: '8px', color: 'green', fontSize: '22px', fontFamily: 'LobsterRegular', height: '30px', width: '100%', backgroundColor: '#29f608', borderRadius: '5px', padding: '2px', textAlign: 'center', cursor: 'pointer' }}>Buy</Box>}          
                      <h3 style={{ position: 'absolute', color: '#00fa9a1c', fontSize: '24px', fontFamily: 'LobsterRegular', marginTop: '0', textAlign: 'center' }}>Ground Number <br /> 4</h3>
                      {isLockedGround4?<div style={{position:'absolute',top:0,zIndex:'1',left:0 ,width:'100%',height:'100%',backgroundColor:'red',background: '#00000087',display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                        <LockIcon sx={{ width: "40px", height: "40px", color: "#08ecf6" ,borderRadius:'15px',zIndex:'2'}}/>
                        <h3 style={{zIndex:'2',color:'#08ecf6',fontFamily:'KalamRegular',fontSize:'14px',flexGrow:'0.1'}}>Complete Ground 3 <br /> Invite +20 friends</h3>
                      </div>:<div></div>}
                      

                    </Stack>
                    
                  </Stack>
                </Stack>
              </Stack>

              <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 ,zIndex:'3',}} elevation={3}>
                <BottomNavigation
                  showLabels
                  sx={{backgroundColor:'#000'}}
                  
                >
                  <BottomNavigationAction sx={{color:'#fff'}} onClick={handleOpenModalProfile} label="profile" icon={<People />} />
                </BottomNavigation>
              </Paper>

              {/* {isBuyGround?<Box onClick={buyPlan1}  sx={{padding:"3px",color:"#45EBA5", width:'100%',height:'24px',borderRadius:'10px' ,boxShadow:'00px 0px 5px 1px rgba(21, 255, 0, 1)',marginTop:'12px',backgroundColor:'#12390d6b',cursor:"pointer",alignContent:"center",textAlign:"center" }}>
              Start Farming
            </Box>:<Box/>} */}
              {/* {<Box onClick={handleCloseModal}  sx={{padding:"3px",color:"#eb4545", width:'100%',height:'24px',borderRadius:'10px' ,boxShadow:'0px 0px 5px 1px #ff0601',marginTop:'12px',backgroundColor:'#222831',cursor:"pointer",alignContent:"center",textAlign:"center" }}>
              Back
            </Box>} */}
            </Stack>
          </Modal> : openModalRegister || !isGameStart ?
            <Modal open={openModalRegister}
              hideBackdrop={true}
              onClose={handleCloseModalRegister}>
              <Stack justifyContent={'center'} direction={'column'} sx={{ backgroundColor: '#222831', height: '100%', padding: '20px', textAlign: 'center', alignItems: 'center', color: '#EEEEEE', fontFamily: 'OrbitronBold' }}>
                <h1>Super Arbuz</h1>
                <img alt='watermeliniconRegister' src={watermelonIcon} className='watermeliniconRegister' />
                <h3>Market open Time: <span style={{ color: 'red' }}>7/6/2024</span></h3>
                <CountdownTimer />
                {handleOnregisterBtn? <Button onClick={registerUser} sx={{ backgroundColor: '#02957a', fontFamily: 'ArchivoBlackRegular', color: '#ffffff' }} variant="contained">register</Button>:<Box/>}
                <div>
                  <p style={{ color: '#fff' }}>Username: {user}</p>
                </div>
              </Stack>
            </Modal> :openModalWallet?
            <Modal 
              open={handleOpenModalWallet}
              hideBackdrop={true}
              onClose={handleCloseModalWallet}>
              <Stack
                component="form"
                sx={{height:'100vh'}}
                autoComplete="off"
              >
                <div style={{textAlign:'center'}}>
                  <h1 style={{fontFamily:'LobsterRegular',color:'#45EBA5'}}>SWAP</h1>
                </div>
                
                <input placeholder='HITEX' className='inputWallet' autoFocus type='number'  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}/>
                <input placeholder='MELON' className='inputWallet' autoFocus type='number'  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}/>
               <Box sx={{backgroundColor:'#29f608',color:'green',padding:'16px',margin:'16px',borderRadius:'5px',textAlign:'center',fontFamily:'KalamRegular',cursor:'pointer'}}>SWAP</Box>
              </Stack>
            </Modal>: openSwapPanel?
            <Modal 
                open={handleOpenSwapPanel}
                hideBackdrop={true}
                onClose={handleCloseSwapPanel}>
                  
                <Stack sx={{height:'100vh',color:'#fff',fontFamily:'LobsterRegular'}} direction={'column'} alignItems={'center'} justifyContent={'space-evenly'}>
                  <span style={{ fontSize: '24px' }}>CONNECT WALLET</span>
                  <Stack direction={'column'}>
                    <Stack direction={'row'}>
                    <FormControl sx={{width:'200px',color:'#fff'}}>
                      <InputLabel sx={{color:'#fff'}}  id="demo-simple-select-label" >Wallet</InputLabel>
                      <Select
                      sx={{color:'#fff'}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={swapCodeConnect}
                        label="Wallet"
                        onChange={handleOnSwapCodeConnect}
                      >
                        <MenuItem value={1}>bitbanker</MenuItem>
                        <MenuItem value={2}>ByBit</MenuItem>
                        <MenuItem  value={3}>OKX</MenuItem>
                        <MenuItem value={4}>HITEX</MenuItem>
                      </Select>
                    </FormControl>
                    <span  onClick={onSwapConnectClick} style={{marginLeft:'16px', padding: '8px', textAlign: 'center', fontSize: '14px', borderRadius: '5px', background: '#29f608', color: 'green', cursor: 'pointer',alignSelf:'center' }}>CONNECT</span>
                  
                    </Stack>
                    <input value={inputValueSwapCode} onChange={handleChangeSwapCode} type='text' autoFocus className='inputSwap' placeholder='input code HITEX'  />

                    </Stack>
                    <Box sx={{position:'absolute',top:'16px',color:'red',cursor:'pointer'}}>
                      <KeyboardReturnIcon onClick={handleCloseSwapPanel} sx={{fontSize:'35px'}}/>
                    </Box>
                </Stack>
            </Modal>:
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
              <Grid sx={{ paddingLeft: '16px', paddingRight: '16px', flex: 1 }} container direction={'column'} wrap='nowrap' >

                <Grid item  >
                  <Stack sx={{ paddingTop: '5px' }} direction='row' alignItems='center' justifyContent={'space-between'} >
                    <PersonPinIcon onClick={handleOpenModalProfile} sx={{ color: '#21ABA5', width: '40px', height: '40px', cursor: 'pointer' }} className='account-icon sm-auto' />
                    <Stack alignItems={'center'} direction={'row'}>
                      <BlurOnIcon sx={{ color: '#24d332', width: '20px', height: '20px' }} />
                      <MoreVertIcon sx={{ color: '#21ABA5', width: '30px', height: '30px' }} />
                    </Stack>
                  </Stack>
                  <h4 className='brand-name'>Super Arbuz</h4>
                  <Stack direction='row' alignItems='center' justifyContent={'space-between'}>
                    <p className='brand-name-powered'>{timeNow}</p>

                    {isClimeClose ? <p className='time-day-close'>{timeDay}</p> : <p className='time-day-open'>{timeDay}</p>}
                    <Stack direction={'row'} spacing={1}>
                      <Stack  justifyContent={'center'} alignItems={'center'} sx={{ backgroundColor: '#222831', width: '80px', height: '25px', borderRadius: '3px', boxShadow: '0 0 5px #000', border: 'solid 1px #45eba696' }} className='sm-auto'>
                        {connectSwap?<Stack direction={'row'}>
                          <PriceCheckIcon sx={{ color: '#45EBA5', fontSize: '16px' }} /><div onClick={handleOpenSwapPanel} style={{cursor:'pointer'}} className='top-box-left-text-connect'>hitex swap</div>
                        </Stack> :<div onClick={handleOpenSwapPanel} style={{cursor:'pointer'}} className='top-box-left-text'>hitex swap</div>}
                      </Stack>
                      <Stack justifyContent={'center'} alignItems={'center'} sx={{ backgroundColor: '#45EBA5', width: '35px', height: '25px', borderRadius: '3px' }} className='sm-auto'>
                        <div className='top-box-right-text'>M</div>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Box sx={{ width: '100%' }} className='div-main'>
                    <div className='main-icon'>
                      <div className='circle' >
                        <svg>
                          <circle cx={125} cy={125} r={125}></circle>
                        </svg>
                      </div>
                      <div className='circle' >
                        <svg>
                          <circle cx={120} cy={120} r={120}></circle>
                        </svg>
                      </div>
                      <div className='circle' >
                        <svg>
                          <circle cx={115} cy={115} r={115}></circle>
                        </svg>
                      </div>
                      <div className='circle' >
                        <svg>
                          <circle cx={110} cy={110} r={110}></circle>
                        </svg>
                      </div>
                      <div className='circle' >
                        <svg>
                          <circle cx={105} cy={105} r={105}></circle>
                        </svg>
                      </div>
                      <div className='circle' >
                        <svg>
                          <circle cx={100} cy={100} r={100}></circle>
                        </svg>
                      </div>
                      <div className='circle' >
                        <svg>
                          <circle cx={95} cy={95} r={95}></circle>
                        </svg>
                      </div>
                      <div className='circle' >
                        <svg>
                          <circle cx={90} cy={90} r={90}></circle>
                        </svg>
                      </div>
                      <div className='circle' >
                        <svg>
                          <circle cx={85} cy={85} r={85}></circle>
                        </svg>
                      </div>
                      <img alt='watermelinicon' src={watermelonIcon} className='watermelonIcon' />
                    </div>
                  </Box>
                  <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ paddingTop: '20px' }}>
                    <div className='mined-token'>
                      <Stack direction={'row'}>
                        <Odometer number={climedCoinWitoutPoint} />.<Odometer number={climedCoinWithPoint} />
                      </Stack>
                    </div>
                    <div className='max-token'>/{tokenToClime} MELON</div>
                  </Stack>
                  <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                    {isWater ? <ShowerIcon sx={{ width: "30px", height: "30px", color: "#21ABA5" }} /> : <Box />}
                    {isFertilizer ? <BackpackIcon sx={{ width: "30px", height: "30px", color: "#21ABA5" }} /> : <Box />}
                    {isPoisons ? <BatteryStdIcon sx={{ width: "30px", height: "30px", color: "#21ABA5" }} /> : <Box />}
                  </Stack>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                  {//free space 
                  }
                </Grid>
                <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}  >
                  <Stack direction={'column'}>
                    <FiberManualRecordIcon sx={{ color: '#00fd15', width: '6px' }} />
                    <div style={{ color: '#EEEEEE', fontSize: '14px', fontFamily: 'KalamRegular' }}>
                      WaterMelon
                    </div>
                    <Stack direction={'column'}>
                      <div style={{ color: '#1D566E', fontSize: '10px', fontFamily: 'KalamRegular' }}>
                        All coin claimed
                      </div>
                      <div style={{ color: '#45EBA5', fontSize: '14px', fontFamily: 'KalamRegular' }}>
                        {allTokenClimed} Melon
                      </div>
                      <div style={{ color: '#1D566E', fontSize: '10px', fontFamily: 'KalamRegular' }}>
                        session
                      </div>
                      <div style={{ color: '#45EBA5', fontSize: '14px', fontFamily: 'KalamRegular' }}>
                        {session}
                      </div>

                      {isClimeClose ? (<Stack justifyContent={'center'} alignItems={'center'} sx={{ backgroundColor: '#222831', width: '80px', height: '24px', borderRadius: '20px', boxShadow: '1px 1px 1px rgb(255 0 0)', border: 'solid 1px #eb454596', marginTop: '12px' }} className='sm-auto'>
                        <div style={{ color: '#EEEEEE', fontSize: '10px', fontFamily: 'KalamRegular' }}>
                          claim Token
                        </div>
                      </Stack>) : isTokenClimeFull ? (<Stack onClick={onClimeTokenClick} justifyContent={'center'} alignItems={'center'} sx={{ backgroundColor: '#222831', width: '80px', height: '24px', borderRadius: '20px', boxShadow: '1px 1px 1px rgba(21, 255, 0, 1)', border: 'solid 1px #45eba696', marginTop: '12px', cursor: 'pointer' }} className='sm-auto' >
                        <div style={{ color: '#EEEEEE', fontSize: '10px', fontFamily: 'KalamRegular' }}>
                        claim Token
                        </div>
                      </Stack>) : (<Stack justifyContent={'center'} alignItems={'center'} sx={{ backgroundColor: '#222831', width: '80px', height: '24px', borderRadius: '20px', boxShadow: '1px 1px 1px rgb(255 0 0)', border: 'solid 1px #eb454596', marginTop: '12px' }} className='sm-auto'>
                        <div style={{ color: '#EEEEEE', fontSize: '10px', fontFamily: 'KalamRegular' }}>
                        claim Token
                        </div>
                      </Stack>)}
                    </Stack>
                  </Stack>
                  <Stack alignItems={'center'} justifyContent={'center'}>
                    <div style={{ color: '#EEEEEE', fontSize: '16px', fontFamily: 'KalamRegular' }}>
                    claim Detail
                    </div>
                    <div style={{ color: '#1D566E', fontSize: '10px', fontFamily: 'KalamRegular' }}>
                      time to claim
                    </div>
                    <div style={{ color: '#eb4545', fontSize: '14px', fontFamily: 'OrbitronBold' }}>
                      {timeToClime}
                    </div>
                    <div style={{ color: '#eb4545', fontSize: '14px', fontFamily: 'OrbitronBold' }}>
                      {day}
                    </div>
                  </Stack>
                  <Stack alignItems={'center'} justifyContent={'center'}>
                    <div style={{ color: '#EEEEEE', fontSize: '18px', fontFamily: 'KalamRegular' }}>
                      $ 0.0006944
                    </div>
                    <Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'} sx={{ width: '100px', height: '24px', borderRadius: '10px', boxShadow: '0px 0px 5px 1px rgb(0 0 0)', marginTop: '12px', backgroundColor: '#12390d6b' }} className='sm-auto'>
                      <PriceCheckIcon sx={{ color: '#45EBA5', fontSize: '16px' }} />
                      <div style={{ color: '#45EBA5', fontSize: '16px', fontFamily: 'KalamRegular' }}>
                      $  {(allTokenClimed*0.0006944).toFixed(2)} 
                      </div>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item >
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ backgroundColor: '#222831', width: '100%', height: '40px', borderRadius: '3px', boxShadow: '0px -2px 1px rgb(62 67 61)', marginTop: '12px' }} className='sm-auto'>
                    <Stack direction={'row'}>
                      <div style={{ color: '#EEEEEE', fontSize: '10px', fontFamily: 'KalamRegular' }}>
                        referalls: 
                      </div>
                      <div style={{ color: '#00fd15',paddingLeft:'3px', fontSize: '12px', fontFamily: 'ArchivoBlackRegular' }}>
                      {allFriends}
                      </div>
                    </Stack>
                    <Stack direction={'row'}>

                      <div style={{ color: '#EEEEEE', fontSize: '10px', fontFamily: 'KalamRegular' }}>
                        all your money:
                      </div>
                      <ArrowDropUpIcon sx={{ color: '#00fd15', width: '16px' }} />
                      <div style={{ color: '#00fd15', fontSize: '12px', fontFamily: 'KalamRegular' }}>
                        00,000 $
                      </div>

                    </Stack>
                    <Stack direction={'row'}>
                      <div style={{ color: '#EEEEEE', fontSize: '10px', fontFamily: 'KalamRegular' }}>
                        all market money:
                      </div>
                      <div style={{ color: '#00fd15', fontSize: '12px', fontFamily: 'KalamRegular' }}>
                        000,000
                      </div>
                    </Stack>
                  </Stack>
                  <Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                    <Button sx={{ backgroundColor: '#222831', fontFamily: 'ArchivoBlackRegular', color: '#ffffff' }} variant="contained">Wallet</Button>
                    {/* <Button onClick={buyPlan1} sx={{backgroundColor:'#02957a',fontFamily:'ArchivoBlackRegular',color:'#ffffff'}} variant="contained">Market</Button> */}
                    {/* {<Button onClick={handleOpenModalProfile} sx={{backgroundColor:'#222831',fontFamily:'ArchivoBlackRegular',color:'#ffffff'}} variant="contained">Profile</Button>} */}
                    {/* <Button onClick={handleOpenModal} sx={{backgroundColor:'#222831',fontFamily:'ArchivoBlackRegular',color:'#ffffff'}} variant="contained">Market</Button> */}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
    }
    </div>


  );

}

export default App;
