import React, { useState, useEffect } from 'react';
import Odometer from './odometer'
import { Stack } from '@mui/material';
function CountdownTimer() {
  const targetDate = new Date("July 7, 2024 00:00:00").getTime();
  const [timeRemaining, setTimeRemaining] = useState(targetDate - new Date().getTime());

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTimeRemaining(targetDate - new Date().getTime());
    }, 1000);


    return () => {
      clearInterval(countdownInterval);
    };
  }, [targetDate]);


  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  return (
    <div>
        <Stack direction={'row'} sx={{marginBottom:'10px'}}>
            <Odometer number={days}/>:<Odometer number={hours}/>: <Odometer number={minutes}/>:<Odometer number={seconds}/> 
        </Stack>
    </div>
  );
}

export default CountdownTimer;