import { useEffect ,useState} from 'react'
import Steper from './steper'
function Odometer({number}){
    const [nums, setNums] = useState([]);

    useEffect(() => {
        setNums(`${number}`.split(''));
      }, [number]);
    useEffect(()=>{
        requestAnimationFrame(animate);
    },[nums])
    function animate (){
        const elems = document.getElementsByClassName('stepper')
        Array.from(elems).forEach(function(e) {
            requestAnimationFrame(()=>{
                const num = e.dataset.num;
                e.style.top = `-${20 *parseInt(num)}px`
                e.style.transitionDelay = `${100 *(10-parseInt(num))}`
            })
        });
    } 
    return <div className="odometer">
        {nums.map(function(value,index){
            return <Steper key={index} num={value}/>
        })}
    </div>
}
export default Odometer;