import React, { useEffect, useState } from 'react';
import axios from 'axios';
import profleImage from './images/watermelon.png'
const TelegramProfileImage = ({ botId, userId, telegramToken }) => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        const fetchProfileImage = async () => {
            try {
                // دریافت اطلاعات پروفایل
                const profileResponse = await axios.get(`https://api.telegram.org/bot${telegramToken}/getUserProfilePhotos`, {
                    params: { user_id: userId } 
                });
                
                const profiles = profileResponse.data;
               
                if (profiles.result.photos[0]) {
                    const tmpFileId = profiles.result.photos[0].slice(-1)[0].file_id;
                   
                    // دریافت اطلاعات فایل
                    const fileResponse = await axios.get(`https://api.telegram.org/bot${telegramToken}/getFile`, {
                        params: { file_id: tmpFileId }
                    });
                    
                    const fileData = fileResponse.data;
                    
                    if (!fileData.ok) {
                        console.error("Failed to get file: " + fileData.description);
                        return;
                    }

                    const filePath = fileData.result.file_path;
                    
                    const fileUrl = `https://api.telegram.org/file/bot${telegramToken}/${filePath}`;
                 
                    // // ذخیره تصویر به مسیر محلی
                    // const localFilePath = `user_photos/${userId}.jpg`;
                    // const imageResponse = await axios.get(fileUrl, { responseType: 'blob' });
                    // console.log(imageResponse);
                    // const localUrl = URL.createObjectURL(imageResponse.data);
                    setImageUrl(fileUrl);
                }
            } catch (error) {
                console.error("Failed to fetch profile image:", error);
            }
        };

        fetchProfileImage();
    }, [botId, userId, telegramToken]);

    return (
        <div>
            {imageUrl ? <img src={imageUrl} alt="Telegram Profile" style={{width:'40px',height:'40px',borderRadius:'100%'}}/> : <img src={profleImage} style={{ width: '60px', height: '60px' }} alt='prifle image' /> }
        </div>
    );
};

export default TelegramProfileImage;